import { get } from "lodash";
import { Link } from "react-router-dom";
import getServices from "../config/services";
import { useEffect } from "react";
import { SuperSEO } from "react-super-seo";

function Services() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SuperSEO
                title="Aapli Gaadi Services: Best Car Rental platform to Explore the Unexplored"
                description="Aapli Gaadi is a marketplace for cars on rent originated from the Konkan region of Maharashtra. From short road trips to quick near-city drives for supply pick-up, and regional food runs, we have the cheapest car rental options for all your needs!"
                lang="en"
            />
            <section className="hero-wrap hero-wrap-2 js-fullheight" style={{ backgroundImage: `url('/image/bg_1.jpg')`, height: '398px', backgroundPosition: '10% 0%' }}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start" style={{ height: '398px;' }}>
                        <div className="col-md-9 ftco-animate pb-5 fadeInUp ftco-animated">
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <Link to="/">Home <i className="ion-ios-arrow-forward"></i></Link>
                                </span>
                                <span>
                                    <Link to="/services">Services</Link>
                                </span>
                            </p>
                            <h1 className="mb-3 bread">Services by <a href='http://www.aapligaadi.com' target={'_self'}>Aapli Gaadi</a></h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-md-7 text-center heading-section ">
                            <span className="subheading">Services</span>
                            <h2 className="mb-3">Our Latest Services</h2>
                        </div>
                    </div>
                    <div className="row">
                        {
                            getServices().map((service, index) => {
                                return (
                                    <>
                                        <div className="col-md-3" key={`aapli-gaadi-service-${index}`}>
                                            <div className="services services-2 w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className={service.icon}></span>
                                                </div>
                                                <div className="text w-100">
                                                    <h3 className="heading mb-2">{service.heading}</h3>
                                                    <p>{service.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    )
}
export default Services;