import { head } from "lodash";

export default function getServices() {
    return [
        {
            icon: 'flaticon-wedding-car',
            heading: 'Self Drive Rental',
            description: `Are you holding license for atleast 2 years? Great! Now you can rent a car with Aapli Gaadi and enjoy your vacations!`
        },
        {
            icon: 'flaticon-transportation',
            heading: 'Car Rentals with Driver',
            description: `Don't have a license? No worries! We provide car rentals with driver.`
        },
        {
            icon: 'flaticon-transportation',
            heading: 'Pick and Drop Services',
            description: `Want a solution for Airport/Railway station pick and drop? @Aapli Gaadi, we provide Airport / Railway station pick and drop services.`
        },
        {
            icon: 'flaticon-transportation',
            heading: 'Whole City Tour',
            description: `Explore the beauty of Konkan with Aapli Gaadi.`
        }
    ]
}