import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WebLayout from './shared/layout/layout.component';
import Index from './pages/index.component';
import BookNow from './pages/bookNow.component';
import JoinUs from './pages/joinUs.component';
import ContactUs from './pages/contactUs.component';
import ServiceFinder from './pages/serviceFinder.component';
import Services from './pages/services.component';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<WebLayout />}>
          <Route path="/" element={
            <Index />
          }></Route>
          <Route path='/services' element={
            <Services />
          }></Route>
          <Route path="/book-now" element={
            <BookNow />
          }></Route>
          <Route path="/join-us" element={
            <JoinUs />
          }></Route>
          <Route path="/contact" element={
            <ContactUs />
          }></Route>
          <Route path="/services/:serviceType/:location" element={
            <ServiceFinder />
          }></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
