import { Car } from "../models/cars.model";

function getWithDriverCars(): Array<Car> {
    return [
        {
            "Brand": "Hyundai",
            "Name": "Aura",
            "Charges": [3800],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli"
            ],
            "Hub": "Malvan, Maharashtra",
            "isSelfDrive": false,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [15],
            "Image": "aura.jpeg",
            "Details": {
                "Fuel": "Petrol + CNG",
                "SeatingCapacity": 5,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "28"
            }
        },
        {
            "Brand": "Mahindra",
            "Name": "Xylo",
            "Charges": [4800],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli"
            ],
            "Hub": "Malvan, Maharashtra",
            "isSelfDrive": false,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [18],
            "Image": "xylo.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 7,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "12"
            }
        },
        {
            "Brand": "Toyota",
            "Name": "Innova",
            "Charges": [4900],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Kudal",
                "Malvan",
                "Tarkarli"
            ],
            "Hub": "Malvan, Maharashtra",
            "isSelfDrive": false,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [18],
            "Image": "innova.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 7,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "12"
            }
        },
        {
            "Brand": "Maruti Suzuki",
            "Name": "Ertiga",
            "Charges": [4200],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kankavli, Maharashtra",
            "isSelfDrive": false,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [22],
            "Image": "ertiga_new.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 6,
                "LuggageCapacity": 3,
                "Transmission": "Manual",
                "Avg/Ltr": "15"
            }
        },
        {
            "Brand": "Toyota",
            "Name": "Innvova",
            "Charges": [4800],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kankavli, Maharashtra",
            "isSelfDrive": false,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [25],
            "Image": "innova.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 7,
                "LuggageCapacity": 4,
                "Transmission": "Manual",
                "Avg/Ltr": "12"
            }
        },
        {
            "Brand": "Force",
            "Name": "Tempo Traveller (AC)",
            "Charges": [8500],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kankavli, Maharashtra",
            "isSelfDrive": false,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [38],
            "Image": "traveller-ac.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 20,
                "LuggageCapacity": 6,
                "Transmission": "Manual",
                "Avg/Ltr": "7"
            }
        },
        {
            "Brand": "Force",
            "Name": "Tempo Traveller (Non-AC)",
            "Charges": [6500],
            "HalfDayCharges": [],
            "UOM": "Day",
            "StartTime": "12 AM",
            "EndTime": "12 AM",
            "Locations": [
                "Kankavli",
                "Vaibhavwadi"
            ],
            "Hub": "Kankavli, Maharashtra",
            "isSelfDrive": false,
            "KMIncluded": ["150"],
            "ExtraKMCharges": [28],
            "Image": "traveller.jpeg",
            "Details": {
                "Fuel": "Diesel",
                "SeatingCapacity": 20,
                "LuggageCapacity": 6,
                "Transmission": "Manual",
                "Avg/Ltr": "8"
            }
        }
    ]
}
export default getWithDriverCars;