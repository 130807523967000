import { Link } from "react-router-dom";

function WebFooter() {
    return (
        <footer className="ftco-footer ftco-bg-dark ftco-section">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4 ml-md-5">
                            <h2 className="ftco-heading-2">Self Driving Car Services</h2>
                            <ul className="list-unstyled">
                                <li><Link to="services/self-driving-car-rental/kudal" className="py-2 d-block">Self driving car rental services in Kudal</Link></li>
                                <li><Link to="services/self-driving-car-rental/malvan" className="py-2 d-block">Self driving car rental services in Malvan</Link></li>
                                <li><Link to="services/self-driving-car-rental/tarkarli" className="py-2 d-block">Self driving car rental services in Tarkarli</Link></li>
                                <li><Link to="services/self-driving-car-rental/vaibhavwadi" className="py-2 d-block">Self driving car rental services in Vaibhavwadi</Link></li>
                                <li><Link to="services/self-driving-car-rental/kankavli" className="py-2 d-block">Self driving car rental services in Kankavli</Link></li>
                                <li><Link to="services/self-driving-car-rental/navi-mumbai" className="py-2 d-block">Self driving car rental services in Navi Mumbai</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4 ml-md-5">
                            <h2 className="ftco-heading-2">Car Rental Services</h2>
                            <ul className="list-unstyled">
                                <li><Link to="services/car-rental/kudal" className="py-2 d-block">Car rental services in Kudal</Link></li>
                                <li><Link to="services/car-rental/malvan" className="py-2 d-block">Car rental services in Malvan</Link></li>
                                <li><Link to="services/car-rental/tarkarli" className="py-2 d-block">Car rental services in Tarkarli</Link></li>
                                <li><Link to="services/car-rental/vaibhavwadi" className="py-2 d-block">Car rental services in Vaibhavwadi</Link></li>
                                <li><Link to="services/car-rental/kankavli" className="py-2 d-block">Car rental services in Kankavli</Link></li>
                                <li><Link to="services/car-rental/navi-mumbai" className="py-2 d-block">Car rental services in Navi Mumbai</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4">
                            <h2 className="ftco-heading-2">Customer Support</h2>
                            <ul className="list-unstyled">
                                <li><a href="#" className="py-2 d-block">FAQ</a></li>
                                <li><a href="#" className="py-2 d-block">Payment Option</a></li>
                                <li><a href="#" className="py-2 d-block">Booking Tips</a></li>
                                <li><a href="#" className="py-2 d-block">How it works</a></li>
                                <li><a href="#" className="py-2 d-block">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4">
                            <h2 className="ftco-heading-2">Have a Questions?</h2>
                            <div className="block-23 mb-3">
                                <ul>
                                    {/* <li><span className="icon icon-map-marker"></span><span className="text">203 Fake St. Mountain View, San
                                        Francisco, California, USA</span></li> */}
                                    <li><a href="tel:+917208388380"><span className="icon icon-phone"></span><span className="text">+91 720 838 8380</span></a></li>
                                    <li><a href="tel:+918286816608"><span className="icon icon-phone"></span><span className="text">+91 828 681 6608</span></a></li>
                                    {/* <li><a href="#"><span className="icon icon-envelope"></span><span
                                        className="text">info@yourdomain.com</span></a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">

                        <p>
                            Copyright &copy;
                            <script>document.write(new Date().getFullYear());</script> All rights reserved | Powered By &nbsp;
                            <i className="icon-heart color-danger" aria-hidden="true"></i> by <a href="https://crewtechsolution.in"
                                target="_blank">Crewtech Solutions</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default WebFooter;