import React from 'react';
import { Outlet } from 'react-router-dom';
import WebFooter from '../footer/footer.component';
import WebHeader from '../header/header.component';
import "../../css/style.css"

function WebLayout() {
    return (
        <>
            <WebHeader />
            <Outlet />
            <WebFooter />
        </>

    )
}
export default WebLayout